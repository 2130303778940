@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply px-5 py-3 border-2 text-sm font-bold text-center rounded-md lg:text-lg;
}

.title {
  @apply text-[18px] md:text-xl lg:text-2xl font-[600] my-3 w-full;
}

.text {
  @apply text-sm mb-2 w-full leading-relaxed sm:leading-loose;
}

* {
  box-sizing: border-box;
}


/* p {
  @apply text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl;
} */


/* Webkit (Safari/Chrome) */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(0 74 135);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.e-con-inner {
  display: none;
}

iframe .elementor-background-slideshow__slide__image {
  display: none;
}

.ast-icon {
  visibility: hidden !important;
  display: none !important;
}

.bkntc_service_list {
  overflow: scroll !important;
}

scrollbar-width: thin;
scrollbar-color: #888 #f1f1f1;